import React from 'react';
import { Box, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Card } from '@mui/material';

const metasGlobais = [
  { meta: 'Aumentar vendas', porcentagem: 75 },
  { meta: 'Ampliar mercado', porcentagem: 121 },
  { meta: 'Reduzir custos', porcentagem: 0 },
  { meta: 'Melhoria de processos', porcentagem: 20 },
  { meta: 'Mensuração de desempenho', porcentagem: 0 },
  { meta: 'Aumentar visibilidade', porcentagem: 100 },
];

const AvaliacaoMetasGlobais: React.FC = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
          <Paper elevation={3} sx={{ padding: 4, maxWidth: '100%', margin: 'auto' }}>
            <Card
              sx={{
                padding: 2,
                textAlign: 'center',
                backgroundColor: (theme) => theme.palette.secondary.main,
                color: 'white',
                marginBottom: 2,
              }}
            >
              Índice de Sucesso
            </Card>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Metas</TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        minWidth: '150px',   // Garante um espaço mínimo para o texto caber
                      }}
                    >
                      Média Geral
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metasGlobais.map((meta, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" sx={{ fontSize: '1rem' }}>{meta.meta.toUpperCase()}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          backgroundColor: meta.porcentagem >= 75 ? 'lightgreen' : '#FFC7CE',
                          color: meta.porcentagem >= 75 ? 'green' : (theme) => theme.palette.secondary.main, 
                          fontSize: '1rem',
                        }}
                      >
                        {meta.porcentagem.toFixed(2)}%
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: '1rem',
                        }}
                      >
                        {meta.porcentagem >= 75 ? (
                          <Typography>
                            <strong>Parabéns!!!</strong> Sua empresa está alcançando ou superando as metas especificadas.
                          </Typography>
                        ) : (
                          <Typography>
                            <strong>Alerta!</strong> O andamento desta meta global da empresa não está indo bem.
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AvaliacaoMetasGlobais;