import axios, { AxiosInstance } from 'axios';

let restClientService: RestClientService | null = null; 

export async function getRestClient(): Promise<RestClientService> {
  if (!restClientService) {
    try {
      const response = await fetch('/config.json');
      const config = await response.json();
      const baseUrl = config.apiBaseUrl;

      if (!baseUrl) {
        throw new Error('API base URL is not defined in the configuration file.');
      }

      restClientService = new RestClientService(baseUrl);
    } catch (error) {
      console.error('Error initializing RestClient:', error);
      throw error;
    }
  }
  return restClientService;
}

class RestClientService {
  private axiosInstance: AxiosInstance;

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // Set the bearer token received during login
  setBearerToken(token: string): void {
    this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  // Method to perform GET requests
  async get<T>(endpoint: string): Promise<T> {
    const response = await this.axiosInstance.get<T>(endpoint);
    return response.data;
  }

  // Method to perform POST requests
  async post<T>(endpoint: string, body: Record<string, any>): Promise<T> {
    const response = await this.axiosInstance.post<T>(endpoint, body);
    return response.data;
  }

  // Method to perform PUT requests
  async put<T>(endpoint: string, body: Record<string, any>): Promise<T> {
    const response = await this.axiosInstance.put<T>(endpoint, body);
    return response.data;
  }

  // Method to perform DELETE requests
  async delete<T>(endpoint: string): Promise<T> {
    const response = await this.axiosInstance.delete<T>(endpoint);
    return response.data;
  }
}