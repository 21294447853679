import React, { useState } from "react";
import {
    Box,
    Paper,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Card,
    SelectChangeEvent,
    TextField,
} from "@mui/material";

type Dados = {
    [key: string]: number;
};

const dadosGrandesAreas: Dados = {
    "Finanças": 50,
    "Vendas": 20,
    "Processos / Produção": 0,
    "Pessoas": 85
};

const dadosFuncionarios: Dados = {
    "Vinicius": 50,
    "Mateus": 80,
    "Marco": 60,
    "Gabriel": 90
};

const AvaliacaoPlanoDeAcao: React.FC = () => {
    const [areaSelecionada, setAreaSelecionada] = useState("Todas");
    const [funcionarioSelecionado, setFuncionarioSelecionado] = useState("Todos");
    const [pesquisaArea, setPesquisaArea] = useState("");
    const [pesquisaFuncionario, setPesquisaFuncionario] = useState("");

    const handleAreaChange = (event: SelectChangeEvent<string>) => {
        setAreaSelecionada(event.target.value as string);
    };

    const handleFuncionarioChange = (event: SelectChangeEvent<string>) => {
        setFuncionarioSelecionado(event.target.value as string);
    };

    const renderMensagemFuncionario = (porcentagem: number) => {
        if (porcentagem >= 75) {
            return (
                <Typography sx={{ color: "green" }}>
                    Este funcionário está com uma boa taxa de conclusão de ações. Continue assim!
                </Typography>
            );
        }
        return (
            <Typography sx={{ color: "red" }}>
                Este funcionário está com baixa taxa de conclusão de ações, verifique seus prazos e tome as ações se necessário.
            </Typography>
        );
    };

    const renderMensagemArea = (porcentagem: number) => {
        if (porcentagem >= 75) {
            return (
                <Typography sx={{ color: "green" }}>
                    Esta grande área está com uma boa taxa de conclusão de ações. Continue assim!
                </Typography>
            );
        }
        return (
            <Typography sx={{ color: "red" }}>
                Esta grande área está com baixa taxa de conclusão de ações. Avalie suas estratégias e tome as ações necessárias.
            </Typography>
        );
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={3}>
                {/* Filtro de Grande Área */}
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>Selecione a Grande Área</InputLabel>
                        <Select value={areaSelecionada} onChange={handleAreaChange}>
                            <MenuItem value="Todas">Todas</MenuItem>
                            {Object.keys(dadosGrandesAreas).map((area) => (
                                <MenuItem key={area} value={area}>
                                    {area}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* Barra de pesquisa para Grandes Áreas */}
                    <TextField
                        fullWidth
                        label="Pesquisar Grande Área"
                        variant="outlined"
                        value={pesquisaArea}
                        onChange={(e) => setPesquisaArea(e.target.value)}
                    />
                </Grid>

                {/* Tabela de Grandes Áreas */}
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Índice de Conclusão das Grandes Áreas
                    </Typography>
                    <Paper elevation={3}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"><strong>Grande Área</strong></TableCell>
                                        <TableCell align="center"><strong>Porcentagem</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(dadosGrandesAreas)
                                        .filter(([area]) =>
                                            area.toLowerCase().includes(pesquisaArea.toLowerCase())
                                        )
                                        .map(([area, porcentagem]) => (
                                            (areaSelecionada === "Todas" || areaSelecionada === area) && (
                                                <TableRow key={area}>
                                                    <TableCell align="center">{area}</TableCell>
                                                    <TableCell align="center">{porcentagem.toFixed(2)}%</TableCell>
                                                </TableRow>
                                            )
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    {areaSelecionada !== "Todas" && (
                        <Card
                            sx={{
                                padding: 2,
                                marginTop: 2,
                                backgroundColor:
                                    dadosGrandesAreas[areaSelecionada] >= 75 ? "lightgreen" : "lightpink",
                            }}
                        >
                            {renderMensagemArea(dadosGrandesAreas[areaSelecionada])}
                        </Card>
                    )}
                </Grid>

                {/* Filtro e pesquisa de Funcionário */}
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>Selecione o Funcionário</InputLabel>
                        <Select value={funcionarioSelecionado} onChange={handleFuncionarioChange}>
                            <MenuItem value="Todos">Todos</MenuItem>
                            {Object.keys(dadosFuncionarios).map((funcionario) => (
                                <MenuItem key={funcionario} value={funcionario}>
                                    {funcionario}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* Barra de pesquisa para Funcionários */}
                    <TextField
                        fullWidth
                        label="Pesquisar Funcionário"
                        variant="outlined"
                        value={pesquisaFuncionario}
                        onChange={(e) => setPesquisaFuncionario(e.target.value)}
                    />
                </Grid>

                {/* Tabela de Funcionários */}
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Índice de Conclusão dos Funcionários
                    </Typography>
                    <Paper elevation={3}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"><strong>Funcionário</strong></TableCell>
                                        <TableCell align="center"><strong>Porcentagem</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(dadosFuncionarios)
                                        .filter(([funcionario]) =>
                                            funcionario.toLowerCase().includes(pesquisaFuncionario.toLowerCase())
                                        )
                                        .map(([funcionario, porcentagem]) => (
                                            (funcionarioSelecionado === "Todos" || funcionarioSelecionado === funcionario) && (
                                                <TableRow key={funcionario}>
                                                    <TableCell align="center">{funcionario}</TableCell>
                                                    <TableCell align="center">{porcentagem.toFixed(2)}%</TableCell>
                                                </TableRow>
                                            )
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    {funcionarioSelecionado !== "Todos" && (
                        <Card
                            sx={{
                                padding: 2,
                                marginTop: 2,
                                backgroundColor:
                                    dadosFuncionarios[funcionarioSelecionado] >= 75 ? "lightgreen" : "lightpink",
                            }}
                        >
                            {renderMensagemFuncionario(dadosFuncionarios[funcionarioSelecionado])}
                        </Card>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default AvaliacaoPlanoDeAcao;
