import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import PageTitle from '../components/PageTitle';

const Relatorios: React.FC = () => {
  const [periodoInicio, setPeriodoInicio] = useState<string>('2025-01');
  const [periodoFim, setPeriodoFim] = useState<string>('2026-12');
  const [relatorioGerado, setRelatorioGerado] = useState(false);

  const valoresEmpresa = [
    'QUALIDADE',
    'PRECO AC.',
    'BOM GOSTO'
  ];

  const handleGerarRelatorio = () => {
    setRelatorioGerado(true);
  };

  return (
    <Box sx={{ p: 3 }}>
      <PageTitle
        title='RELATÓRIOS'
        message='Preparamos esta aba para você imprimir a hora que quiser.'
      />

      {/* Cabeçalho da Empresa */}
      <Typography variant="h4" align="center" sx={{ mt: 4, mb: 6 }}>
        Vieira Murara Software
      </Typography>

      {/* Seletor de Período */}
      <Grid container spacing={3} justifyContent="center" sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <TextField
            label="Data Início"
            type="month"
            fullWidth
            value={periodoInicio}
            onChange={(e) => setPeriodoInicio(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Data Fim"
            type="month"
            fullWidth
            value={periodoFim}
            onChange={(e) => setPeriodoFim(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleGerarRelatorio}
            sx={{ height: '48px' }}
          >
            Gerar
          </Button>
        </Grid>
      </Grid>

      {relatorioGerado && (
        <Box sx={{ maxWidth: 892, margin: 'auto', paddingX: '1vw' }}>
          {/* Seção Missão */}
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6" gutterBottom color="secondary">
              Missão
            </Typography>
            <Typography>
              Desenvolver software para pessoas carentes
            </Typography>
          </Paper>

          {/* Seção Visão */}
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6" gutterBottom color="secondary">
              Visão
            </Typography>
            <Typography>
              Faturar até 500k mês até 2030
            </Typography>
          </Paper>

          {/* Tabela de Valores */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom color="secondary" sx={{ mb: 2 }}>
              Valores
            </Typography>
            <Table>
              <TableBody>
                {valoresEmpresa.map((valor, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      <Typography variant="body1">
                        {valor}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>

          {/* Rodapé do Relatório */}
          <Typography variant="body2" align="center" sx={{ mt: 4, color: 'text.secondary' }}>
            Relatório gerado em: {new Date().toLocaleDateString()}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Relatorios;