import React, { useState } from 'react';
import { Box, Typography, TextField, Grid, Paper, Button, Drawer, Backdrop } from '@mui/material';
import Empresa from '../types/negocio/Empresa';
import EmpresaService from '../services/empresaService';

const Fundamentos: React.FC = () => {
  const [empresa, setEmpresa] = useState<Empresa>({} as Empresa);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const empresaService = new EmpresaService();

  const handleChange = (field: keyof Empresa, value: any) => {
    setEmpresa((prev: Empresa) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = async () => {
    try {
      await empresaService.save(empresa);
      console.log("Empresa salva com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar empresa:", error);
    }
  };

  const toggleSidebar = (open: boolean) => {
    setIsSidebarOpen(open);
  };

  return (
    <Box sx={{ padding: 4, maxWidth: 800, margin: 'auto', position: 'relative' }}>
      <Backdrop
        open={isSidebarOpen}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1,
          background: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(4px)',
        }}
      />
      <Typography variant="h5" gutterBottom>
        Informações básicas para o planejamento
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography>Nome da empresa</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={empresa.nome}
              onChange={(e) => handleChange('nome', e.target.value)}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography>Ano início do planejamento</Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              value={empresa.anoInicioPlanejamento}
              onChange={(e) => handleChange('anoInicioPlanejamento', Number(e.target.value))}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography>Planejar para quantos anos?</Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              value={empresa.planejarQuantosAnos}
              onChange={(e) => handleChange('planejarQuantosAnos', Number(e.target.value))}
            />
          </Paper>
        </Grid>
      </Grid>

      <Box mt={4} display="flex" justifyContent="center">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/skg-29jvfsU"
          title="Vídeo explicativo"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box >

      <Box mt={4}>
        <Paper sx={{ padding: 2 }}>
          <Box display="flex" gap={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Missão"
              value={empresa.missao}
              onChange={(e) => handleChange('missao', e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => toggleSidebar(true)}
              sx={{ height: 57 }}
            >
              Criar com IA
            </Button>
          </Box>
          <Typography mt={2}>
            Exemplo:<br></br>
            <strong>Google</strong>: “Organizar as informações do mundo todo e torná-las acessíveis e úteis em caráter universal”.<br></br>
            <strong>Microsoft</strong>: “Ajudar as pessoas e empresas em todo o mundo a concretizarem todo o seu potencial”.<br></br>
            <strong>Vale</strong>: "Transformar recursos naturais em prosperidade e desenvolvimento sustentável."<br></br>
          </Typography>
        </Paper>
      </Box>
      <Box mt={4}>
        <Paper sx={{ padding: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Visão"
            value={empresa.visao}
            onChange={(e) => handleChange('visao', e.target.value)}
            InputProps={{ style: { color: 'white' } }}
          />
          <Typography mt={2}>
            Exemplo:<br></br>
            <strong>Petrobras</strong>: “Ser reconhecida como a melhor empresa de energia, garantindo o máximo valor para os seus acionistas e para a sociedade”.<br></br>
            <strong>Magazine Luiza</strong>: “Ser a maior e melhor plataforma digital de varejo do Brasil, conectando pessoas e negócios”.<br></br>
            <strong>Amazon</strong>: "Ser a empresa mais centrada no cliente do mundo, onde as pessoas podem encontrar e descobrir qualquer coisa que queiram comprar".<br></br>
          </Typography>
        </Paper>
      </Box>
      <Box mt={4}>
        <Paper sx={{ padding: 2 }}>
          <TextField
            fullWidth
            label="Valores"
            variant="outlined"
            value={empresa.visao}
            onChange={(e) => handleChange('visao', e.target.value)}
            InputProps={{ style: { color: 'white' } }}
          />
          <Typography mt={2}>
            Exemplo:<br></br>
            <strong>Natura</strong>: “Bem-estar bem. Transparência nas relações. Compromisso com a verdade. Sustentabilidade como princípio”.<br></br>
            <strong>Apple</strong>: “Inovação contínua. Excelência em design e simplicidade. Foco na experiência do cliente".<br></br>
            <strong>Tesla</strong>: "Sustentabilidade ambiental. Inovação disruptiva. Ética e excelência no trabalho".<br></br>
          </Typography>
        </Paper>
      </Box>
      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Box>


      {/* Sidebar (Criar com IA) */}
      <Drawer
        anchor="right"
        open={isSidebarOpen}
        onClose={() => toggleSidebar(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '33%',
            padding: 4,
          },
        }}
      >
        <Typography variant="h5">Construindo sua Missão!</Typography>
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 4 }}>
          Qual é o produto ou serviço que oferecemos?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          Qual é a principal atividade da nossa empresa?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          Quais são os nossos métodos, diferenciais ou valores que guiam nossas operações?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          Que princípios, tecnologias ou abordagens utilizamos?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          Quem é nosso público-alvo ou cliente principal?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          Quais necessidades ou problemas resolvemos para esse público?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          Que contribuição queremos trazer para a sociedade, o mercado ou nossos clientes?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          Como nosso trabalho faz a diferença no mundo ou no cotidiano das pessoas?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          O que nos diferencia dos concorrentes?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          Por que os clientes escolhem a nossa empresa em vez de outra?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          Que princípios ou crenças fundamentais guiam nossas decisões e ações?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Typography variant="body2" sx={{ marginBottom: 1, marginTop: 1 }}>
          Como demonstramos nossos valores no dia a dia?
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          color='secondary'
          value={empresa.missao}
          onChange={(e) => handleChange('missao', e.target.value)}
          sx={{ marginBottom: 2 }}
          InputProps={{
            sx: {
              height: 48
            },
          }}
        />
        <Box display={"flex"} justifyContent={"end"} gap={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => toggleSidebar(false)}
            sx={{ marginTop: 2 }}
          >
            Fechar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => toggleSidebar(false)}
            sx={{ marginTop: 2 }}
          >
            Gerar
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Fundamentos;