import { getRestClient } from "./restClientService";

type AuthStateCallback = (isAuthenticated: boolean, response?: any | null) => void;

class AuthService {

    private listeners: any[] = [];

    signInWithEmailAndPassword(username: String, password: String): Promise<void> {
        return getRestClient()
            .then(restClient => {
                return restClient.post('/auth/login', { username, password })
                    .then(response => {
                        this.listeners.forEach((listener: any) => {
                            listener(true, response);
                        });
                    }).catch(error => {
                        console.error(error);
                    });
            });
    }

    onAuthStateChanged(callback: AuthStateCallback): void {
        this.listeners.push(callback);
    }

}

export const authService = new AuthService();