import React, { useState } from 'react';
import InfoHeader from '../components/InfoHeader';
import { Box, TextField, Grid, Paper, Button, List, ListItem, ListItemText, IconButton, Card } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const MetasGlobais: React.FC = () => {
  const [metas, setMetas] = useState<string[]>([
    'Aumentar vendas',
    'Ampliar mercado',
    'Reduzir custos',
    'Melhoria de processos',
    'Mensuração de desempenho',
    'Aumentar visibilidade',
  ]);
  const [showInput, setShowInput] = useState(false);
  const [newMeta, setNewMeta] = useState('');

  const handleAddMeta = () => {
    if (newMeta.trim()) {
      setMetas([...metas, newMeta]);
      setNewMeta('');
      setShowInput(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMeta(e.target.value);
  };

  const handleDeleteMeta = (indexToDelete: number) => {
    const updatedMetas = metas.filter((_, index) => index !== indexToDelete);
    setMetas(updatedMetas);
  };

  return (
    <Box>
      <InfoHeader
        title='Metas Globais'
        message='Só altere estes campos ao sentir necessidade. Esses dados serão usados mais adiante. Para facilitar a linha de raciocínio, você pode definir até 10 metas globais que serão atreladas às metas específicas. O Think Way já vem com sugestões de metas que se encaixam na maioria das empresas, mas você pode alterá-las para que as metas fiquem adequadas à realidade da sua empresa. Aumentar o volume de produção ou de vendas gerais podem ser exemplos de metas globais.'
        isImportant={true}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          mt: 4
        }}
      >
        <Grid
          container
          justifyContent="center"
        >
          <Grid
            item
            xs={14}
            sm={12}
            md={10}
            lg={8}
            xl={6}
          >
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                maxWidth: { xs: '95%', sm: '90%', md: '80%', lg: '70%', xl: '60%' },
                margin: 'auto'
              }}
            >
              <Card
                color='secondary'
                sx={{
                  padding: 1,
                  textAlign: 'center',
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  color: 'white',
                }}
              >
                Metas Globais da Empresa
              </Card>

              <List sx={{ marginTop: 2 }}>
                {metas.map((meta, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteMeta(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={meta} />
                  </ListItem>
                ))}
              </List>
              {showInput && (
                <Box sx={{ marginTop: 2 }}>
                  <TextField
                    fullWidth
                    label="Digite a meta global"
                    value={newMeta}
                    onChange={handleInputChange}
                    color="secondary"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAddMeta();
                      }
                    }}
                    autoFocus
                  />
                  <Button
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    fullWidth
                    onClick={handleAddMeta}
                  >
                    Adicionar
                  </Button>
                </Box>
              )}
              {!showInput && (
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    marginTop: 2
                  }}
                  onClick={() => setShowInput(true)}
                >
                  Adicionar Meta Global
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MetasGlobais;