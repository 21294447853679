// src/LoginPage.tsx

import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { authService } from './services/authenticationService';

const LoginPage: React.FC = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError(null);
    try {
      await authService.signInWithEmailAndPassword(email, password).then(e => {
        localStorage.setItem('isAuthenticated', 'true');
        navigate('/tw/diretrizes');
      });      
    } catch (err) {
      setError("Erro ao fazer login. Verifique suas credenciais.");
    }
  };

  const handleLoginEnter = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 2 }}>
      <Alert severity="warning">
        A plataforma está aberta apenas a convidados durante a fase alfa.
      </Alert>
      <Container maxWidth="xs" sx={{ mt: 6 }}>
        <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img
            src="/logo192.png"
            alt="Exemplo"
            style={{ maxWidth: '200px', height: 'auto' }}
          />
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            Login
          </Typography>
          {error && (
            <Typography color="error" variant="body2" align="center">
              {error}
            </Typography>
          )}
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Senha"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onKeyDown={handleLoginEnter}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleLogin}
          >
            Entrar
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default LoginPage;