import React, { useState } from 'react';
import InfoHeader from '../components/InfoHeader';
import { Box, Grid, Paper, Card, Typography, MenuItem, Select, FormControl, InputLabel, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

const MetasEspecificas: React.FC = () => {
  // Mock de áreas cadastradas
  const [areas] = useState<string[]>(['Finanças', 'Vendas', 'Processos / Produção', 'Pessoas']);

  // Estado para as metas específicas de cada área
  const [metas, setMetas] = useState<{ [key: string]: { direcao: string; indicador: string; metaGlobal: string }[] }>(
    areas.reduce((acc, area) => ({ ...acc, [area]: [] }), {})
  );

  // Handlers para adicionar uma nova meta específica
  const handleAddMeta = (area: string) => {
    setMetas({
      ...metas,
      [area]: [...metas[area], { direcao: '', indicador: '', metaGlobal: '' }],
    });
  };

  // Handlers para atualizar uma meta específica
  const handleMetaChange = (area: string, index: number, field: string, value: string) => {
    const updatedMetas = metas[area].map((meta, i) =>
      i === index ? { ...meta, [field]: value } : meta
    );
    setMetas({ ...metas, [area]: updatedMetas });
  };

  return (
    <Box>
      {/* Header */}
      <InfoHeader
        title="Metas Específicas"
        message="Aqui você pode cadastrar múltiplas metas específicas para cada área."
        isImportant={true}
      />

      {/* Grid com os cards de áreas */}
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
        {areas.map((area, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
            <Paper elevation={3} sx={{ padding: 2, height: '100%' }}>
              <Card
                sx={{
                  padding: 1,
                  textAlign: 'center',
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  color: 'white',
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6" sx={{ fontSize: '1rem' }}>{area}</Typography>
              </Card>

              {/* Tabela de metas específicas */}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Direção</TableCell>
                    <TableCell>Indicador</TableCell>
                    <TableCell>Meta Global</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metas[area].map((meta, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <FormControl fullWidth size="small">
                          <InputLabel color='secondary'>Direção</InputLabel>
                          <Select
                            value={meta.direcao}
                            onChange={(e) => handleMetaChange(area, i, 'direcao', e.target.value)}
                            color='secondary'
                          >
                            <MenuItem value="">
                              <em>Selecionar</em>
                            </MenuItem>
                            <MenuItem value="Aumentar">Aumentar</MenuItem>
                            <MenuItem value="Diminuir">Diminuir</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          size="small"
                          value={meta.indicador}
                          onChange={(e) => handleMetaChange(area, i, 'indicador', e.target.value)}
                          placeholder="Indicador"
                          color='secondary'
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth size="small">
                          <InputLabel color='secondary'>Meta Global</InputLabel>
                          <Select
                            value={meta.metaGlobal}
                            color="secondary"
                            onChange={(e) => handleMetaChange(area, i, 'metaGlobal', e.target.value)}
                          >
                            <MenuItem value="">
                              <em>Selecionar</em>
                            </MenuItem>
                            <MenuItem value="Ampliar mercado">Ampliar mercado</MenuItem>
                            <MenuItem value="Aumentar vendas">Aumentar vendas</MenuItem>
                            <MenuItem value="Crescimento de mercado">Crescimento de mercado</MenuItem>
                            <MenuItem value="Reduzir custos">Reduzir custos</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {/* Botão para adicionar uma nova meta */}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => handleAddMeta(area)}
                sx={{ mt: 2, fontSize: '0.875rem' }}
              >
                Adicionar Meta
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MetasEspecificas;