import React, { useState } from 'react';
import { Box } from '@mui/material';
import PageTitle from '../components/PageTitle';
import { CustomTabs, CustomTab } from '../components/CustomTabs';
import AvaliacaoMetasGlobais from "./AvaliacaoMetasGlobais";
import AvaliacaoPlanoDeAcao from './AvaliacaoPlanoDeAcao';

const MetasEspecificas = () => <div>Conteúdo de Metas Específicas</div>;

const Avaliacao: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <PageTitle
        title='AVALIAÇÃO'
        message='Tenha uma visão geral da sua estratégia e como ela vem sendo implementada. A partir daqui, você poderá ter insights sobre as ações que devem ser implementadas ou ajustadas.'
      />
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="fullWidth"
      >
        <CustomTab tabTitle="Metas específicas" content={<MetasEspecificas />} />
        <CustomTab tabTitle="Metas globais" content={<AvaliacaoMetasGlobais />} />
        <CustomTab tabTitle="Planos de ação" content={<AvaliacaoPlanoDeAcao />} />
      </CustomTabs>
    </Box>
  );
};

export default Avaliacao;